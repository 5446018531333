import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout/layout"
import { Pagination } from "../components/menus/pagination"
import Seo from "../components/layout/seo"


const ArticleIndex = ({ data }) => {

  return (
    <Layout>
      <Seo title="Articles" />
      <section className="bg-primary-50">
        <div className="container px-0 py-8 mx-auto">

          <div className="md:flex flex-wrap">
            {data.allNodeArticle.edges.map((item, key) => {
              return (
                <div
                  key={key}
                  className="md:w-6/12 lg:w-4/12 p-6 box-border">
                  <div className="bg-white border border-gray-200 shadow-sm h-full rounded rounded-bl-none">
                    {item.node.relationships.field_image.relationships
                      .field_media_image_1 && (
                        <Link to={item.node.path.alias}>
                          <GatsbyImage
                            image={getImage(
                              item.node.relationships.field_image.relationships
                                .field_media_image_1.localFile
                            )}
                            alt="view article"
                            className="w-full mb-1 rounded-t"
                          />
                        </Link>
                      )}
                    <div className="p-6">
                      <Link to={item.node.path.alias}>
                        <h3 className="text-xl mb-3">{item.node.title}</h3>
                      </Link>
                      <div
                        className="text-listing text-sm line-clamp-3"
                        dangerouslySetInnerHTML={{
                          __html: item.node.field_teaser.value,
                        }}
                      />
                    </div>
                  </div>
                </div>
              )
            })
            }
          </div>

          <div className="my-6 text-sm">
            <Pagination pageInfo={data.allNodeArticle.pageInfo} />
          </div>

        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
query($skip: Int!, $limit: Int!) {
  allNodeArticle(
    filter: {relationships: {field_article_destination: {elemMatch: {name: {eq: "Solar PV"}}}}}
    sort: { fields: [created], order: DESC}
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        id
        drupal_internal__nid
        title
        field_teaser {
          value
        }
        created(formatString: "DD MMMM, YYYY")
        status
        path {
          alias
        }
        relationships {
          field_image {
            relationships {
              field_media_image_1 {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 90, width: 800, aspectRatio: 1.8)
                  }
                }
              }
            }
          }
        }
      }
    }
    pageInfo {
        currentPage
        pageCount
        hasNextPage
        hasPreviousPage
        itemCount
        perPage
        totalCount
      }
  }
}
`

export default ArticleIndex